@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #f2f4f6;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
  
.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}